import './App.css';
import React, { Component } from 'react';
import { SocialIcon } from 'react-social-icons';
import Story from "./components/story";
//import nft from './assets/Mintanimation.webm';
import Web3 from "web3";
import Web3Modal from "web3modal";
import WalletConnectProvider from "@walletconnect/web3-provider";
import logomiddle from './assets/header.svg';
import logo from './assets/logo.svg';
import Earning from './components/earning';
import NewsLetter from './components/newsLetter';
import Podcast from './components/podcast';
import Footer from './components/footer';
import mobH from './assets/mobH.svg';
import navbar from './assets/navigation-bar.png';
import wrong from './assets/wrong.png';
import mouse from './assets/mouse.png';
import mouse2 from './assets/mouse2.png';

var Scroll = require('react-scroll');

var Link = Scroll.Link;
var DirectLink = Scroll.DirectLink;
var Element = Scroll.Element;
var Events = Scroll.Events;
var scroll = Scroll.animateScroll;
var scrollSpy = Scroll.scrollSpy;


/*
<div class="homeImages">
								<div class="homeImages1"><img class="rightPic" src={left} /></div>
								<div class="homeImages2"><img class="rightPic" src={right} /></div>
							</div>
*/

let account;
let mintAmount = 1;
let valueOfNFTs = 0;
let totalSupplyNFT;
let totalSupplyNFT2;
let maxMintNFTs;
let onlyLeft;
let owner;
let publicSale;
let user_input;
let wMintAmount;
let myTokens = [];
let myTokens2 = [];
let adminWhitelistAddresses = [];
let adminPanel = 0;
let adminWhitelistAddresses2 = [];
let maxTokensToBuy = "";
let testingvalue;
let wlMint;
let FinalResult;
let wlMsg = "Whitelist Mint";
let public_sale_cost;
let presalesale_cost;
let MAX_PER_WALLET;
let public_mint_status = false;
let preSale_mint_status = false
let preSaleLimit;
let preSaleCost;
let cost;
let preSaleCount;


const ABI = "";

const address = "";
let contract;

class App extends Component {

	state = {
		walletAddress: "",
		totalSupply: "",
		currentPrice: "",
		nextPrice: "",
		nextSessionAmount: "",
		onlyLeftAmount: "",
		statusError: false,
		statusLoading: false,
		success: false,
		nftMintingAmount: '1',
		totalValue: "",
		presaleValue: "0",
		maxmint: '',
		_adminPanel: 0,
		_adminWhitelistAddresses: [],
		_adminWhitelistAddresses2: [],
		_maxTokensToBuy: "",
		_testingValue: '',
		_wlMint: '',
		_FinalResult: '',
		_wlMsg: 'Whitelist Mint',
		_public_sale_cost: '',
		_presalesale_cost: '',
		_MAX_PER_WALLET: '',
		_public_mint_status: '',
		_preSale_mint_status: '',
		_cost: '',
		_mintStatus: false,
		_preSaleCost: '',
		_preSaleLimit: '',
		_preSaleCount: '',
		_navbarOpen: 0,
		_mouseClick: 0
	}

	closeNav = () => {
		const navBar = document.querySelector(".navBarCon");
		navBar.classList.add("hidden");
	}

	onSubmitMinus = async event => {
		event.preventDefault();

		mintAmount = mintAmount - 1;

		if (mintAmount < 1) {
			mintAmount = 1
		}


		if (owner == account) {
			console.log("owner : " + owner)
			onlyLeft = 1000 - totalSupplyNFT;

			if (mintAmount > onlyLeft) {
				mintAmount = onlyLeft;
			}

			valueOfNFTs = mintAmount * 0;
			wMintAmount = mintAmount;

			this.setState({ nftMintingAmount: mintAmount });

			this.setState({ totalValue: valueOfNFTs });
		} else {


			if (totalSupplyNFT < 1000) {

				onlyLeft = MAX_PER_WALLET - publicSale;

				if (mintAmount > onlyLeft) {
					mintAmount = onlyLeft;
				}
				valueOfNFTs = mintAmount * this.state._cost;
				wMintAmount = mintAmount;


				this.setState({ nftMintingAmount: mintAmount });

				this.setState({ totalValue: valueOfNFTs });

			}
		}
	}

	onSubmitPlus = async event => {
		event.preventDefault();

		//,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,
		mintAmount = mintAmount + 1;

		if (owner == account) {
			console.log("owner : " + owner)
			onlyLeft = 1000 - totalSupplyNFT;

			if (mintAmount > onlyLeft) {
				mintAmount = onlyLeft;
			}

			valueOfNFTs = mintAmount * 0;
			wMintAmount = mintAmount;


			this.setState({ nftMintingAmount: mintAmount });

			this.setState({ totalValue: valueOfNFTs });
		} else {

			if (totalSupplyNFT < 1000) {

				onlyLeft = MAX_PER_WALLET - publicSale;
				console.log(onlyLeft);

				if (mintAmount > onlyLeft) {
					mintAmount = onlyLeft;
				}
				valueOfNFTs = mintAmount * this.state._cost;
				wMintAmount = mintAmount;

				this.setState({ nftMintingAmount: mintAmount });

				this.setState({ totalValue: valueOfNFTs });

			}
		}
	}

	onSubmit2 = async event => {
		event.preventDefault();

		console.log(this.state.walletAddress);

		try {
			let owner = await contract.methods.owner().call();


			if (account != owner) {


				try {

					console.log("minAmount:" + mintAmount);
					console.log("valueOfNFTs:" + valueOfNFTs);
					let preSaleMinting = mintAmount + preSaleCount;


					if (preSale_mint_status == true && preSaleMinting <= 200) {
						this.setState({ statusError: false, statusLoading: true });
						await contract.methods.preSale(mintAmount * 1).send({ gasLimit: 285000, from: account, value: this.state.totalValue * 1 });
						this.setState({ statusLoading: false, success: true });
						console.log("Mint Amount :" + this.state.mintAmount);

					} else {
						this.setState({ statusError: false, statusLoading: true });
						await contract.methods.mint(mintAmount * 1).send({ gasLimit: 285000, from: account, value: this.state.totalValue * 1 });
						this.setState({ statusLoading: false, success: true });
						console.log("Mint Amount :" + this.state.mintAmount);
					}


				} catch (err) {
					this.setState({ errorMassage: "ERROR : " + err.message, statusLoading: false, success: false, statusError: true });
					console.log(err);

				}
			} else {

				try {


					console.log("minAmount:" + mintAmount);
					console.log("valueOfNFTs:" + valueOfNFTs);


					this.setState({ statusError: false, statusLoading: true });
					await contract.methods.mint(mintAmount * 1).send({ gasLimit: 285000, from: account, value: this.state.totalValue * 0 });
					this.setState({ statusLoading: false, success: true });
					console.log("Mint Amount :" + this.state.mintAmount);

				} catch (err) {
					this.setState({ errorMassage: "ERROR : " + err.message, statusLoading: false, success: false, statusError: true });
					console.log(err);


				}






			}
		} catch (err) {

			console.log(err);

		}
	}

	walletConnect = async event => {
		event.preventDefault();

		const providerOptions = {
			walletconnect: {
				package: WalletConnectProvider, // required
				options: {
					infuraId: "bf933c3446b3464c988114813a1360ac" // required
				}
			}
		};

		const web3Modal = new Web3Modal({
			network: "mainnet", // optional
			cacheProvider: true, // optional
			providerOptions // required
		});



		const provider = await web3Modal.connect();

		//  Enable session (triggers QR Code modal)
		await provider.enable();

		const web3 = new Web3(provider);
		console.log("provider : " + provider);
		// Subscribe to accounts change
		provider.on("accountsChanged", (accounts) => {
			console.log(accounts);
		});

		// Subscribe to chainId change
		provider.on("chainChanged", (chainId) => {
			console.log(chainId);
		});

		// Subscribe to provider connection
		provider.on("connect", (info) => {
			console.log(info);
		});

		// Subscribe to provider disconnection
		provider.on("disconnect", (error) => {
			console.log(error);
		});

		// test if wallet is connected
		if (web3Modal.cachedProvider) {
			// connected now you can get accounts
			console.log("provider :" + web3Modal.cachedProvider);
			const accounts = await web3.eth.getAccounts();

			account = accounts[0];
			this.setState({ walletAddress: account });

			contract = new web3.eth.Contract(ABI, address);
			console.log("contract :" + contract);

			if (provider) {


				(async () => {


					if (web3Modal.cachedProvider != "walletconnect") {

						const chainId = 1;

						if (window.ethereum.networkVersion !== chainId) {
							try {
								await window.ethereum.request({
									method: 'wallet_switchEthereumChain',
									params: [{ chainId: web3.utils.toHex(chainId) }],
								});
							} catch (err) {
								// This error code indicates that the chain has not been added to MetaMask.
								if (err.code === 4902) {
									await window.ethereum.request({
										method: 'wallet_addEthereumChain',
										params: [
											{
												chainName: 'Ethereum Mainnet',
												chainId: web3.utils.toHex(chainId),
												nativeCurrency: { name: 'Ethereum', decimals: 18, symbol: 'ETH' },
												rpcUrls: ['https://etherscan.io'],

												/*chainName: 'Goerli Test Network',
												chainId: web3.utils.toHex(chainId),
												nativeCurrency: { name: 'Goerli Test Network', decimals: 18, symbol: 'GoerliETH' },
												rpcUrls: ['https://goerli.infura.io/v3/'],*/
											},
										],
									});
								}
							}
						}





						try {

							preSale_mint_status = await contract.methods.preSale_mint_status().call();
							this.setState({ _preSale_mint_status: preSale_mint_status });
							console.log("preSale_mint_status :" + preSale_mint_status);

							public_sale_cost = await contract.methods.publicSaleCost().call();
							this.setState({ _public_sale_cost: public_sale_cost });
							console.log("public_sale_cost :" + public_sale_cost);

							preSaleCost = await contract.methods.preSaleCost().call();
							this.setState({ _preSaleCost: preSaleCost });
							console.log("preSaleCost :" + preSaleCost);

							if (preSale_mint_status == true) {
								cost = preSaleCost;
								this.setState({ _cost: preSaleCost });

							} else {
								cost = public_sale_cost;
								this.setState({ _cost: public_sale_cost });

							}

							totalSupplyNFT = await contract.methods.totalSupply().call();
							this.setState({ totalSupply: totalSupplyNFT });
							console.log("Total Supply:" + totalSupplyNFT);

							publicSale = await contract.methods.balanceOf(account).call();
							this.setState({ myNFTWallet: publicSale });



							MAX_PER_WALLET = await contract.methods.max_per_wallet().call();
							this.setState({ _MAX_PER_WALLET: MAX_PER_WALLET });
							console.log("MAX_PER_WALLET :" + MAX_PER_WALLET);

							public_mint_status = await contract.methods.public_mint_status().call();
							this.setState({ _public_mint_status: public_mint_status });
							console.log("public_mint_status :" + public_mint_status);



							preSaleLimit = await contract.methods.preSaleLimit().call();
							this.setState({ _preSaleLimit: preSaleLimit });
							console.log("preSaleLimit :" + preSaleLimit);

							preSaleCount = await contract.methods.preSaleCount().call();
							this.setState({ _preSaleCount: preSaleCount });
							console.log("preSaleCount :" + preSaleCount);



							owner = await contract.methods.owner().call();
							console.log("Owner" + owner);

							if (owner == account) {
								console.log("owner : " + owner)
								onlyLeft = 1000 - totalSupplyNFT;

								if (mintAmount > onlyLeft) {
									mintAmount = onlyLeft;
								}

								valueOfNFTs = mintAmount * 0;
								wMintAmount = mintAmount;


								this.setState({ nftMintingAmount: mintAmount });

								this.setState({ totalValue: valueOfNFTs });
							} else {
								mintAmount = 1;

								if (totalSupplyNFT == 1000) {

									onlyLeft = 1000 - totalSupplyNFT;
									mintAmount = onlyLeft;
									this.setState({ msg: "SOLD OUT" });

								} else {
									mintAmount = 1;
									onlyLeft = MAX_PER_WALLET - publicSale;

									if (mintAmount > onlyLeft) {
										mintAmount = onlyLeft;
									}
									//mintAmount = onlyLeft;

									valueOfNFTs = mintAmount * this.state._cost;
									wMintAmount = mintAmount;


									this.setState({ nftMintingAmount: mintAmount });

									this.setState({ totalValue: valueOfNFTs });
								}
							}


						} catch (err) {
							console.log("err: " + err);

						}
					} else {

						const chainId = 1;

						if (WalletConnectProvider.networkVersion !== chainId) {
							try {
								await WalletConnectProvider.request({
									method: 'wallet_switchEthereumChain',
									params: [{ chainId: web3.utils.toHex(chainId) }],
								});
							} catch (err) {
								// This error code indicates that the chain has not been added to MetaMask.
								if (err.code === 4902) {
									await window.ethereum.request({
										method: 'wallet_addEthereumChain',
										params: [
											{
												chainName: 'Ethereum Mainnet',
												chainId: web3.utils.toHex(chainId),
												nativeCurrency: { name: 'Ethereum', decimals: 18, symbol: 'ETH' },
												rpcUrls: ['https://etherscan.io'],

												/*chainName: 'Goerli Test Network',
												chainId: web3.utils.toHex(chainId),
												nativeCurrency: { name: 'Goerli Test Network', decimals: 18, symbol: 'GoerliETH' },
												rpcUrls: ['https://goerli.infura.io/v3/'],*/
											},
										],
									});
								}
							}
						}




						try {
							preSale_mint_status = await contract.methods.preSale_mint_status().call();
							this.setState({ _preSale_mint_status: preSale_mint_status });
							console.log("preSale_mint_status :" + preSale_mint_status);

							public_sale_cost = await contract.methods.publicSaleCost().call();
							this.setState({ _public_sale_cost: public_sale_cost });
							console.log("public_sale_cost :" + public_sale_cost);

							preSaleCost = await contract.methods.preSaleCost().call();
							this.setState({ _preSaleCost: preSaleCost });
							console.log("preSaleCost :" + preSaleCost);

							if (preSale_mint_status == true) {
								cost = preSaleCost;
								this.setState({ _cost: preSaleCost });

							} else {
								cost = public_sale_cost;
								this.setState({ _cost: public_sale_cost });

							}

							totalSupplyNFT = await contract.methods.totalSupply().call();
							this.setState({ totalSupply: totalSupplyNFT });
							console.log("Total Supply:" + totalSupplyNFT);

							publicSale = await contract.methods.balanceOf(account).call();
							this.setState({ myNFTWallet: publicSale });




							MAX_PER_WALLET = await contract.methods.max_per_wallet().call();
							this.setState({ _MAX_PER_WALLET: MAX_PER_WALLET });
							console.log("MAX_PER_WALLET :" + MAX_PER_WALLET);

							public_mint_status = await contract.methods.public_mint_status().call();
							this.setState({ _public_mint_status: public_mint_status });
							console.log("public_mint_status :" + public_mint_status);





							preSaleLimit = await contract.methods.preSaleLimit().call();
							this.setState({ _preSaleLimit: preSaleLimit });
							console.log("preSaleLimit :" + preSaleLimit);

							preSaleCount = await contract.methods.preSaleCount().call();
							this.setState({ _preSaleCount: preSaleCount });
							console.log("preSaleCount :" + preSaleCount);



							owner = await contract.methods.owner().call();
							console.log("Owner" + owner);

							if (owner == account) {
								console.log("owner : " + owner)
								onlyLeft = 1000 - totalSupplyNFT;

								if (mintAmount > onlyLeft) {
									mintAmount = onlyLeft;
								}

								valueOfNFTs = mintAmount * 0;
								wMintAmount = mintAmount;


								this.setState({ nftMintingAmount: mintAmount });

								this.setState({ totalValue: valueOfNFTs });
							} else {
								mintAmount = 1;

								if (totalSupplyNFT == 1000) {

									onlyLeft = 1000 - totalSupplyNFT;
									mintAmount = onlyLeft;
									this.setState({ msg: "SOLD OUT" });

								} else {
									mintAmount = 1;
									onlyLeft = MAX_PER_WALLET - publicSale;

									if (mintAmount > onlyLeft) {
										mintAmount = onlyLeft;
									}
									//mintAmount = onlyLeft;

									valueOfNFTs = mintAmount * this.state._cost;
									wMintAmount = mintAmount;


									this.setState({ nftMintingAmount: mintAmount });

									this.setState({ totalValue: valueOfNFTs });
								}
							}


						} catch (err) {
							console.log("err: " + err);

						}
					}
				})();

				//.....................................................................//

				// Legacy providers may only have ethereum.sendAsync
				const chainId = await provider.request({
					method: 'eth_chainId'
				})

			} else {

				// if the provider is not detected, detectEthereumProvider resolves to null
				console.error('Please install a Valid Wallet');
				alert('A valid provider could not be found!');

			}

		}

	}

	walletDisconnect = async event => {
		event.preventDefault();


		const providerOptions = {
			walletconnect: {
				package: WalletConnectProvider, // required
				options: {
					infuraId: "bf933c3446b3464c988114813a1360ac" // required
				}
			}
		};

		const web3Modal = new Web3Modal({
			network: "mainnet", // optional
			cacheProvider: true, // optional
			providerOptions // required
		});



		// disconnect wallet
		web3Modal.clearCachedProvider();
		window.location.reload();

	}

	navbarOpen = async event => {
		event.preventDefault();

		this.setState({ _navbarOpen: 1 });
	}

	closeNav = async event => {
		event.preventDefault();

		this.setState({ _navbarOpen: 0 });
	}

	mouseClick = async event => {
		event.preventDefault();

		this.setState({ _mouseClick: 1 });
	}

	render() {

		return (

			<div class="allWrap">
				<div class="light">

					<div class="headers">

						<div class="h1">
							<img class="navbar" onClick={this.navbarOpen} src={navbar} />
							<img class="logo" src={logo} />
							<div class="connect">
								<div ><Link activeClass="active-link" id="fontSize" to="about" spy={true} smooth={true} duration={550}>GROW</Link></div>
								<div ><Link activeClass="active-link" id="fontSize" to="earn" spy={true} smooth={true} duration={550}>EARN</Link></div>
								<div ><Link activeClass="active-link" id="fontSize" to="newsletter" spy={true} smooth={true} duration={550}>NEWSLETTER</Link></div>
								<div ><Link activeClass="active-link" id="fontSize" to="podcast" spy={true} smooth={true} duration={550}>PODCAST</Link></div>

							</div>

						</div>

					</div>
					{this.state._navbarOpen > 0 ?
						(<div class="navBarCon" data-aos="fade-right">
							<img onClick={this.closeNav} src={wrong} />
							<div ><Link activeClass="navLink" id="fontSize2" to="about" spy={true} smooth={true} duration={550} onClick={this.closeNav}>GROW</Link></div>
							<div ><Link activeClass="navLink" id="fontSize2" to="earn" spy={true} smooth={true} duration={550} onClick={this.closeNav}>EARN</Link></div>
							<div ><Link activeClass="navLink" id="fontSize2" to="newsletter" spy={true} smooth={true} duration={550} onClick={this.closeNav}>NEWSLETTER</Link></div>
							<div ><Link activeClass="navLink" id="fontSize2" to="podcast" spy={true} smooth={true} duration={550} onClick={this.closeNav}>PODCAST</Link></div>

						</div>) : null}
					<div class="cont">

						<div class="introduction">
							<img class="middleH" src={logomiddle} />
							<img class="middleHMob" src={mobH} />
							{this.state._mouseClick > 0 ?
							(<Link to="about" activeClass="mouse" class="mouse" spy={true} smooth={true} duration={550}><img class="mouse2" onClick={this.mouseClick} src={mouse2}/></Link>):
							(<Link to="about" activeClass="mouse" class="mouse" spy={true} smooth={true} duration={550}><img class="mouse2" onClick={this.mouseClick} src={mouse}/></Link>)}

							
						</div>

					</div>

					<Element name="about">
						<Story />
					</Element>

					<Element name="earn">
						<Earning />
					</Element>

					<Element name="newsletter">
						<NewsLetter />
					</Element>

					<Element name="podcast">
						<Podcast />
					</Element>

					<Footer />

				</div>
			</div >)
	}
}

export default App;
