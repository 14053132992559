import React, { Component, useEffect } from 'react';
import '../App.css';
import "aos/dist/aos.css";
import Aos from 'aos';
import header from '../assets/strategyH.svg';
import btn1 from '../assets/btn1.svg';
import chart from '../assets/strategy.svg';
import header2 from '../assets/mediaH.svg';
import btn2 from '../assets/btn2.svg';
import chart2 from '../assets/media.svg';
import header3 from '../assets/chainH.svg';
import btn3 from '../assets/btn3.svg';
import chart3 from '../assets/chain.svg';
import header4 from '../assets/XplorH.svg';
import btn4 from '../assets/btn4.svg';
import chart4 from '../assets/Xplor.svg';
import p1 from '../assets/p1.png';
import p2 from '../assets/p2.png';
import p3 from '../assets/p3.png';
import p4 from '../assets/p4.png';

let ghost = 'https://smassets.pages.dev/assets/sticky.png';

var Scroll = require('react-scroll');

var Link = Scroll.Link;
var DirectLink = Scroll.DirectLink;
var Element = Scroll.Element;
var Events = Scroll.Events;
var scroll = Scroll.animateScroll;
var scrollSpy = Scroll.scrollSpy;

const Stry = () => {
    useEffect(() => {
        Aos.init({ duration: 4000 });
    }, [])
}

let storyUrl = 'https://fishpricsassets.pages.dev/assets/about.png';

class Story extends Component {

    state = {
        _iframe: 0
    }

    iframe = async event => {
        event.preventDefault();

        this.setState({ _iframe: 1 });
    }

    targetRef = React.createRef();


    render() {
        return (

            <div class="boxWrap2" >

                <Element name="section1">
                    <div>
                        <div class="boxWrap2Sub">
                            <div class="headersAndBtn">
                                <img class="middleS" src={header} />

                                <img onClick={this.iframe} class="btn1" src={btn1} />
                                <div ref={this.targetRef} />

                            </div>

                            <img class="chart" src={chart} />

                        </div>
                        {this.state._iframe > 0 ?
                            (<iframe
                                src="https://calendar.google.com/calendar/appointments/schedules/AcZssZ0PWzspzhjvQKWugLhe51ZQ49Pjdd28T_PD5xdknEMcZtYTqprs8HpdSTEepcGfMw0j_Ozm-vs9?gv=true"
                                width="100%"
                                height="500px"
                                style={{ border: "none" }}
                            >
                            </iframe>) : null}

                    </div>
                </Element>

                <Element name="section2">
                    <div>
                        <div class="boxWrap2Sub">
                            <div class="headersAndBtn">
                                <img class="middleS" src={header2} />
                                <img class="btn1" src={btn2} />
                                <div ref={this.targetRef}></div>
                            </div>

                            <img class="chart" src={chart2} />

                        </div>

                    </div>
                </Element>

                <Element name="section3">
                    <div>
                        <div class="boxWrap2Sub">
                            <div class="headersAndBtn">
                                <img class="middleS" src={header3} />
                                <img class="btn1" src={btn3} />
                            </div>

                            <img class="chart" src={chart3} />

                        </div>

                    </div>
                </Element>

                <Element name="section4">
                    <div>
                        <div class="boxWrap2Sub">
                            <div class="headersAndBtn">
                                <img class="middleS" src={header4} />
                                <img class="btn1" src={btn4} />
                            </div>

                            <img class="chart" src={chart4} />

                        </div>

                    </div>
                </Element>

                <div class="pagesIcons">
                    <div><Link activeClass="onImg1" to="section1" spy={true} smooth={true} duration={550}><img class="pagesIconsImg" src={p1}/></Link></div>
                    <div><Link activeClass="onImg2" to="section2" spy={true} smooth={true} duration={550}><img class="pagesIconsImg" src={p2}/></Link></div>
                    <div><Link activeClass="onImg3" to="section3" spy={true} smooth={true} duration={550}><img class="pagesIconsImg" src={p3}/></Link></div>
                    <div><Link activeClass="onImg4" to="section4" spy={true} smooth={true} duration={550}><img class="pagesIconsImg" src={p4}/></Link></div>
                </div>
            </div>
        )
    }
}

export default Story;

