import React, { Component, useEffect } from 'react';
import '../App.css';
import "aos/dist/aos.css";
import Aos from 'aos';
import book1 from '../assets/book1.svg';
import book2 from '../assets/book1.svg';
import header from '../assets/NLHeader.svg';
import btn from '../assets/readBtn.svg';
import subH from '../assets/subH.svg';

let ghost = 'https://smassets.pages.dev/assets/sticky.png';

const Stry = () => {
    useEffect(() => {
        Aos.init({ duration: 4000 });
    }, [])
}

let storyUrl = 'https://fishpricsassets.pages.dev/assets/about.png';

const linkedin = () => {
    window.open("https://www.linkedin.com/authwall?trk=bf&trkInfo=AQFQqGQx3qZRhAAAAYXUaG6gK56UAhqEv6y80U7YLvYKDp6o8OsTyvX4d0Op1-pvnyWHAqtPtLCmYGPlYBeh2A7ewMns53m4vMegI-E-rRZ0Ytyzurz_4mlxgRYM-cVGAqqGLzw=&original_referer=&sessionRedirect=https%3A%2F%2Fwww.linkedin.com%2Fcompany%2Fswipe-web3-news%2F");
}

class NewsLetter extends Component {

    render() {
        return (

            <div class="boxWrap4" >

                <div class="boxWrap4Sub">

                    <div class="books">
                        <img src={book1} />
                    </div>

                    <div class="middleCon">
                        <div class="headersAndBtn3">
                            <img class="startEarning" src={header} />
                            <img onClick={linkedin} class="btnRead" src={btn} />
                            <img src={subH} />
                        </div>
                    </div>

                    <div class="books">
                        <img src={book2} />
                    </div>
                </div>

            </div>
        )
    }
}

export default NewsLetter;

