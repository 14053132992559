import React, { Component, useEffect } from 'react';
import '../App.css';
import "aos/dist/aos.css";
import Aos from 'aos';
import footerH from '../assets/footerH1.svg';
import footerH2 from '../assets/footerH2.svg';
import i1 from '../assets/i1.png';
import i2 from '../assets/i2.png';
import i3 from '../assets/i3.png';
import i4 from '../assets/i4.png';
import i5 from '../assets/i5.png';
import i6 from '../assets/i6.png';
import i7 from '../assets/i7.png';
import i8 from '../assets/i8.png';
import headers from '../assets/headers.svg';
import border from '../assets/border.png';
import brew from '../assets/brew.png';
import copyright from '../assets/copyright.svg'
import assets41 from '../assets/Asset 41.svg';
import assets42 from '../assets/Asset 42.svg';

const Stry = () => {
    useEffect(() => {
        Aos.init({ duration: 4000 });
    }, [])
}

class Footer extends Component {




    render() {
        return (

            <div class="boxWrap5" >

                <div class="middleCon2">
                    <div class="fSection">
                        <img class="footerH" src={footerH} />

                        <div class="iconMain">
                            <img class="footerH2" src={assets42} />
                            <div class="iconSet">
                                <img src={i1} />
                                <img src={i2} />
                                <img src={i3} />
                                <img src={i4} />
                            </div>
                        </div>

                        <div class="iconMain">
                            <img class="footerH2" src={assets41} />
                            <div class="iconSet">
                                <img src={i5} />
                                <img src={i6} />
                                <img src={i7} />
                                <img src={i8} />
                            </div>
                        </div>
                    </div>

                    <img class="headersList" src={headers} />
                </div>

                <div class="inputField">
                    <img class="brew" src={brew}/>
                   <div class="inputs"><input class="input1" placeholder="ENTER YOUR EMAIL"></input>
                    <input class="input2" placeholder="SIP THE BREW"></input></div>
                </div>
                <img class="headersList2" src={headers} />

                <div class="footerCon">
                    <div>FAQs</div>
                    <div>Chat with us</div>
                    <div>Terms and Conditions</div>
                    <div>Privacy Policy</div>
                    <div>Join our team</div>
                </div>

                <div class="footerConMob">
                    <div class="fSection2">
                    <div>FAQs</div>
                    <div>Chat with us</div>
                    <div>Terms and Conditions</div>
                    </div>
                    <div class="fSection2">
                    <div>Privacy Policy</div>
                    <div>Join our team</div>
                    </div>
                </div>

                <img class="copyrightImg" src={copyright}/>
            </div>
        )
    }
}

export default Footer;

