import React, { Component, useEffect } from 'react';
import '../App.css';
import "aos/dist/aos.css";
import Aos from 'aos';
import startEarning from '../assets/startEarning.svg';
import applyBtn from '../assets/applyBtn.svg';
import halfGlobe from '../assets/halfGlobe.png';
import active from '../assets/active.svg';
import se from '../assets/se.svg';

/*

                    <div class="bars">
                        <img src={bar1} />
                        <img src={bar2} />
                        <img src={bar3} />
                    </div>
*/
const Stry = () => {
    useEffect(() => {
        Aos.init({ duration: 4000 });
    }, [])
}

class Earning extends Component {

	state = {
        _iframe: 0,
        _iframe2: 0
    }

	iframe = async event => {
		event.preventDefault();

		this.setState({ _iframe: 1 });
	}

    iframe2 = async event => {
		event.preventDefault();

		this.setState({ _iframe2: 1 });
	}
    targetRef = React.createRef();


    render() {
        return (

            <div class="boxWrap3" >

                <div class="section1">
                    <div class="headersAndBtn2">
                        <img class="startEarning" src={se} />
                        <img onClick={this.iframe2} class="btn1" src={applyBtn} />
                    </div>
                    {this.state._iframe2 > 0 ?
                    (<iframe
                        src="https://docs.google.com/forms/d/e/1FAIpQLSeP8fYc_WUCWmf7Oy1-2Ni2k_DHlErc4Ke8CIp_wdxHDhpEwg/viewform"
                        width="100%"
                        height="500px"
                        style={{ border: "none" }}
                    >
                    </iframe>):null }
                    <img class="halfGlobe" src={halfGlobe} />
 
                </div>
                <div class="section2">
                    <div class="headersAndBtn2">
                        <img class="startEarning" src={active} />
                    </div>


                    <img onClick={this.iframe} class="applyBtn" src={applyBtn} />
                    {this.state._iframe > 0 ?
                    (<iframe
                        src="https://docs.google.com/forms/d/e/1FAIpQLSeP8fYc_WUCWmf7Oy1-2Ni2k_DHlErc4Ke8CIp_wdxHDhpEwg/viewform"
                        width="100%"
                        height="500px"
                        style={{ border: "none" }}
                    >
                    </iframe>):null }
                    
                </div>
            </div>
        )
    }
}

export default Earning;