import React, { Component, useEffect } from 'react';
import '../App.css';
import "aos/dist/aos.css";
import Aos from 'aos';
import podcastH from '../assets/podcast.svg';
import btn from '../assets/podcastBtn.svg';
import members from '../assets/members.svg';
import ytBtn from '../assets/ytBtn.svg';

const Stry = () => {
    useEffect(() => {
        Aos.init({ duration: 4000 });
    }, [])
}

const podcast = () => {
    window.open("https://www.youtube.com/@charliez360");
}

class Podcast extends Component {

    render() {
        return (

            <div class="boxWrap3" >

                <div class="section1">
                    <div class="headersAndBtn2">
                        <img class="startEarning" src={podcastH} />
                        <img class="btn1" src={btn} />
                    </div>
                    <img class="members" src={members} />

                </div>
                <div class="section2">
                    <div class="headersAndBtn4">
                    <iframe
                                src="https://www.youtube.com/embed/JWMRQcmPzZM"
                                width="100%"
                                height="500px"
                                style={{ border: "none" }}
                            >
                            </iframe>
                    </div>

                    <img class="applyBtn"  onClick={podcast} src={ytBtn} />

                </div>
             </div>
        )
    }
}

export default Podcast;

